import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import logoGLN from '../assets/img/logoleadership.png';
import logoGLNLight from '../assets/img/logoLeadershipDark.png';

const Footer = ({ t }) => {
    const darkTheme = useSelector(state => state.darkTheme)
    const path = useLocation()

    return (
        <div className='full-footer mt-5'>
            <footer className="footer row align-items-center justify-content-between col-12 pt-2 pb-2 ps-0 pe-0 pe-sm-0 m-0">
                <div className='d-flex align-items-center justify-content-around col-12 col-md-6 col-xl-5 GLSFontBold my-4 my-md-0'>
                    <img className='pe-0' src={darkTheme ? logoGLN : logoGLNLight} alt='GLN' style={{width: '137px', height: '54px'}}/>
                    <div className='ps-2'>
                        <div className={`text-left`} style={{color: '#a8e3e6'}}>{t('footer-label-1')}</div>
                        <div className={`text-left`} style={{color: '#a8e3e6'}}>{t('footer-label-2')}</div>
                    </div>
                </div>
                <div className={`d-flex align-items-center justify-content-around col-12 col-md-6 col-xl-7 pe-5 fst-italic ${
                        (path.pathname === "/" || path.pathname.includes("login")) ? '' : darkTheme ? 'bg-success' : 'bg-primary'
                    }`} style={{height: '50px', backgroundColor: '#a8e3e6'}}>
                    <Link to='/faqs' className={`GLSFontBold text-uppercase text-xs`} style={{color: '#045986'}}>{t('FAQs')}</Link>
                    <Link to='/copyright' className={`ms-3 GLSFontBold text-uppercase text-xs`} style={{color: '#045986'}}>{t('copyright')}</Link>
                    <Link to='/terms-of-use' className={`ms-3 GLSFontBold text-uppercase text-xs`} style={{color: '#045986'}}>{t('terms-of-use')}</Link>
                    <Link to='/contact-us' className={`ms-3 me-0 me-sm-5 GLSFontBold text-uppercase text-xs`} style={{color: '#045986'}}>{t('contact-us')}</Link>
                </div>
            </footer>
        </div>
    )
}


export default withNamespaces()(Footer);