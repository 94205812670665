import { NavLink, useLocation } from "react-router-dom"
import logo from '../../assets/img/WLN/WLN23_Logo_Compact_White.png'
import { BsList, BsPersonFill } from 'react-icons/bs'
import { FaRunning, FaCartPlus } from 'react-icons/fa'
import { RiFileCopy2Fill, RiPlayCircleFill } from "react-icons/ri"
import { IoIosColorPalette } from "react-icons/io"
import { FiLogIn } from "react-icons/fi"
import { useDispatch, useSelector } from "react-redux"
import { logout, toggleDarkTheme } from "../../Redux/actions"
import { LanguagesModalButton } from "../LanguagesModal"
import { withNamespaces } from 'react-i18next';
import './NavBar.css'

const NavBar = ({ t }) => {
    const token = window.localStorage.getItem('GLSNToken')
    const darkTheme = useSelector(state => state.darkTheme)
    const dispatch = useDispatch()
    const path = useLocation()

    const handleTheme = () => {
        dispatch(toggleDarkTheme(darkTheme))
    }

    const handleLogout = () => {
        dispatch(logout())
    }

    return (
        <nav className={(token || (path.pathname !== '/' && !path.pathname.includes('login')))
            ? `navbar navbar-expand header sticky-top ${darkTheme ? 'bg-black' : 'bg-primary'}`
            : "navbar navbar-expand header bg-transparent"}>
            {(path.pathname !== '/' && !path.pathname.includes('login')) && <NavLink to='/' className="ms-3" style={{zIndex: 1}}>
                <img className='logo' src={ logo } alt='logo' />
            </NavLink>}
            {token && <>
                <ul className="navbar-nav position-absolute justify-content-center w-100 ms-auto me-auto d-none d-md-flex">
                    <li className="nav-item pe-3">
                        <NavLink to='/videos/sessions'>
                            <span className={path.pathname === '/videos/sessions' ? "text-warning" : "text-white"}> {t("sessions")} </span>
                        </NavLink>
                        {path.pathname === '/videos/sessions' && <div className={`w-100 ${darkTheme ? 'bg-info' : 'bg-white'}`} style={{height: '3px'}}></div>}
                    </li>
                    <li className="nav-item pe-3">
                        <NavLink to='/videos/grander_vision'>
                            <span className={path.pathname === '/videos/grander_vision' ? "text-warning" : "text-white"}> {t("grander-vision")} </span>
                        </NavLink>
                        {path.pathname === '/videos/grander_vision' && <div className={`w-100 ${darkTheme ? 'bg-info' : 'bg-white'}`} style={{height: '3px'}}></div>}
                    </li>
                    <li className="nav-item pe-3">
                        <NavLink to='/videos/bonus'>
                            <span className={path.pathname === '/videos/bonus' ? "text-warning" : "text-white"}> {t("bonus-resources")} </span>
                        </NavLink>
                        {path.pathname === '/videos/bonus' && <div className={`w-100 ${darkTheme ? 'bg-info' : 'bg-white'}`} style={{height: '3px'}}></div>}
                    </li>
                    <li className="nav-item pe-3 d-none d-lg-block">
                        <NavLink to='/discussion_guides'>
                            <span className={path.pathname === '/discussion_guides' ? "text-warning" : "text-white"}>{t("discussion-guides")} </span>
                        </NavLink>
                        {path.pathname === '/discussion_guides' && <div className={`w-100 ${darkTheme ? 'bg-info' : 'bg-white'}`} style={{height: '3px'}}></div>}
                    </li>
                </ul>
            </>}
            <ul className="navbar-nav align-items-center me-3 ms-auto" style={{zIndex: 1051}}>
                <li className="nav-item me-3 ms-auto">
                    <LanguagesModalButton />
                </li>

                {!token && <>
                    <NavLink to='/login/code' className="me-2 me-sm-4 text-white text-uppercase GLSFontBold" style={{zIndex: 1}}>
                        <span className={`${!darkTheme && (path.pathname === '/' || path.pathname.includes('login')) && 'text-dark'}`} style={{ fontSize: '14px' }}>
                        {t("login")} </span><FiLogIn className={`${!darkTheme && path.pathname !== '/' && !path.pathname.includes('login') ? 'text-white' : 'text-success'}`}  size="20px" />
                    </NavLink>
                    <div className={`d-flex align-items-center rounded-pill p-2 ${darkTheme ? 'bg-black' : 'bg-white'}`} onClick={() => handleTheme()}>
                        {darkTheme && <small className="ms-1 text-white">Dark</small>}
                        <div className={`p-2 rounded-circle ${darkTheme ? 'bg-white ms-2' : 'bg-black me-2'}`} style={{height: '19px', width: '19px'}}></div>
                        {!darkTheme && <small className="me-1">White</small>}
                    </div>
                </>}

                <div className="dropdown">
                    {token && <a href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                        <BsList className={'text-warning'} size="25px"/>
                    </a>}

                    {token && <>
                        <ul className={`dropdown-menu ${darkTheme ? 'bg-black' : 'bg-primary'}`} aria-labelledby="dropdownMenuLink" style={{left: 'unset', right: 0}}>
                            <li className="ps-1 pe-1 d-sm-block d-md-none">
                                <NavLink to='/videos/sessions' className="dropdown-item rounded-pill highlight text-white text-end ">
                                    {t("sessions")} <RiPlayCircleFill/>
                                </NavLink>
                            </li>
                            <li className="ps-1 pe-1 d-sm-block d-md-none">
                                <NavLink to='/videos/grander_vision' className="dropdown-item rounded-pill highlight text-white text-end ">
                                    {t("grander-vision")} <RiPlayCircleFill />
                                </NavLink>
                            </li>
                            <li className="ps-1 pe-1 d-sm-block d-md-none">
                                <NavLink to='/videos/bonus' className="dropdown-item rounded-pill highlight text-white text-end ">
                                    {t("bonus")} <RiPlayCircleFill />
                                </NavLink>
                            </li>
                            <li className="ps-1 pe-1 d-sm-block d-lg-none">
                                <NavLink to='/discussion_guides' className="dropdown-item rounded-pill highlight text-white text-end ">
                                    {t("discussion-guides")} <RiFileCopy2Fill />
                                </NavLink>
                            </li>
                            <li className="ps-1 pe-1">
                                <NavLink to='/profile' className="dropdown-item rounded-pill highlight text-white text-end ">
                                    {t("my-profile")} <BsPersonFill />
                                </NavLink>
                            </li>
                            <li className="ps-1 pe-1">
                                <div className="dropdown-item rounded-pill highlight text-white text-end" onClick={() => handleTheme()}>
                                    {darkTheme ? t("light-theme") : t("dark-theme")} <IoIosColorPalette />
                                </div>
                            </li>
                            <li  className="ps-1 pe-1">
                                <NavLink to='/GetContent' className="dropdown-item rounded-pill highlight text-white text-end ">
                                    {t("get-content")} <FaCartPlus />
                                </NavLink>
                            </li>
                            <li  className="ps-1 pe-1" onClick={handleLogout}>
                                <NavLink to='/' className="dropdown-item rounded-pill highlight text-white text-end ">
                                    {t("logout")} <FaRunning />
                                </NavLink>
                            </li>
                        </ul>
                    </>}
                </div>
            </ul>
        </nav>
    )
}

export default withNamespaces()(NavBar);