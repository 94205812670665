import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import bannerWLN1 from '../assets/img/WLN/slide-1920-WLN1-name.jpg'
import bannerWLN2 from '../assets/img/WLN/slide-1920-WLN2-name.jpg'

const Banner = ({ t }) => {
    const language = useSelector(state => state.language.selected.key)
    const bannersEnglish = useSelector(state => state.banners.filter(banner => banner.banner === 1))
    const bannersInternational = useSelector(state => state.banners.filter(banner => banner.banner === 2))
    const selectedBanners = language === 'eng' ? bannersEnglish : bannersInternational

    const darkTheme = useSelector(state => state.darkTheme)
    
    return (
        <div id="bannerSlider" className={`carousel slide ${darkTheme ? 'banner-shadow' : 'banner-shadow-light'} `} data-bs-ride="carousel">
            <div className="carousel-indicators">
                {selectedBanners.map((banner, key) =>
                    <button
                        key={key + banner.title}
                        type="button"
                        data-bs-target="#bannerSlider"
                        data-bs-slide-to={key}
                        className={key === 0
                            ? 'rounded-pill border-0 active'
                            : 'rounded-pill border-0'}    
                        aria-current={key === 0 ? 'true' : ''}
                        aria-label={"Slide" + key}
                        style={{width: '15px', height: '15px', backgroundColor: '#00425E'}}
                    />
                )}
            </div>
            <div className="carousel-inner">
                {selectedBanners.map((banner, key) =>
                    <div key={banner.title + key} className={key === 0 ? 'carousel-item active' : "carousel-item"}>
                        {/* <img src={banner.image} className="d-block w-100" alt={banner.title} /> */}
                        <img src={key % 2 === 0 ? bannerWLN1 : bannerWLN2} className="d-block w-100" alt={banner.title} />
                    </div>
                )}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#bannerSlider" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#bannerSlider" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    )
}

export default withNamespaces()(Banner);