import { Link } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import logoWLNLight from '../assets/img/WLN/WLN23_Logo_Colour.png'
import logoWLNCompactLight from '../assets/img/WLN/WLN23_Logo_Compact_Colour.png'
import logoWLN from '../assets/img/WLN/WLN23_Logo_White.png'
import logoWLNCompact from '../assets/img/WLN/WLN23_Logo_Compact_White.png'
import logo from '../assets/img/logoGLSNow.png'
import logoLight from '../assets/img/logo-color.png'
import { useEffect } from "react";
import { useSelector } from "react-redux";
import $ from 'jquery';
import bgimg1 from '../assets/img/landing-1920-cn-28jun-1.jpg';
import bgimg2 from '../assets/img/landing-1920-cn-28jun-2.jpg';
import bgimg3 from '../assets/img/landing-1920-cn-28jun-3.jpg';
import bgimg1Li from '../assets/img/landing-1920-blanco-28jun-1.jpg';
import bgimg2Li from '../assets/img/landing-1920-blanco-28jun-2.jpg';
import bgimg3Li from '../assets/img/landing-1920-blanco-28jun-3.jpg';

const Landing = ({ t }) => {
    const darkTheme = useSelector(state => state.darkTheme)

    useEffect(() => {
        document.body?.classList.remove(darkTheme ? 'bg-dark' : 'bg-white');
        document.body?.classList.remove(darkTheme ? 'bg-login' : 'bg-login-light');
        document.body?.classList.remove(darkTheme ? 'bg-landing-light': 'bg-landing');
        document.body?.classList.add(darkTheme ? 'bg-landing': 'bg-landing-light');
    }, [darkTheme]);

    useEffect(() => {
        /* var backgroundImages = [bgimg1, bgimg2, bgimg3];
        var backgroundImagesLight = [bgimg1Li, bgimg2Li, bgimg3Li];
            $(function () {
                var i = 0;
                $(".bg-landing").css("background-image", "url(" + backgroundImages[i] + ")");
                $(".bg-landing-light").css("background-image", "url(" + backgroundImagesLight[i] + ")");
                setInterval(function () {
                    i++;
                    if (i == backgroundImages.length) {
                        i = 0;
                    }
                    $(".bg-landing").css("background-image", "url(" + backgroundImages[i] + ")");;
                    $(".bg-landing-light").css("background-image", "url(" + backgroundImagesLight[i] + ")");;
                }, 8000);
            }); */
    });


    return (
        <>
            {/* Desktop Layout */}
            <div className="" style={{ maxWidth: '90%' }}>
                <div className="d-none d-lg-block" style={{minHeight: '75vh'}}>
                    <div className={`d-flex flex-column align-items-center col-12 col-lg-6 col-xl-5 mt-5 ms-5 py-4 ps-3 pe-5`}
                        style={darkTheme ? {backgroundImage: 'linear-gradient(black, transparent)'} : {backgroundImage: 'linear-gradient(white, transparent)'}}>
                        <img className=" col-6 ms-auto me-auto" src={ darkTheme ? logoWLNCompact : logoWLNCompactLight} style={{maxHeight: '160px'}} alt="landingLogo"/>
                        <h5 className={`${darkTheme && 'text-white'} my-5`}>
                            {t("landing-label-1")}
                        </h5>
                        <h5 className={`${darkTheme && 'text-white'} mb-4`}>
                            {t("landing-label-2")}
                        </h5>
                        <div className="d-flex align-items-center justify-content-end">
                            <h2 className={`${darkTheme && 'text-white'} m-0`}><i><b>{t('BY')}</b></i></h2>
                            <img className="ps-5 col-5" src={ darkTheme ? logo : logoLight} style={{maxHeight: '160px'}} alt="landingLogo"/>
                        </div>
                        <div className="mt-5 mb-1">
                            <Link to="/login/code" className="my-2 me-3" style={{minWidth: '200px'}}>
                                <button className="p-1 rounded border-0 landing-btn text-uppercase">{t("access-code-button")}</button>
                            </Link>
                            <Link to="/login/email" className="my-2 ms-3" style={{minWidth: '200px'}}>
                                <button className="p-1 m-0 rounded border-0 landing-btn text-uppercase">{t("access-email-button")}</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* Tablet & Mobile Layout */}
            <div className="d-md-block d-lg-none mobile-landing">
                <center className="d-flex flex-column align-items-center d-lg-block col-12 col-lg-6 pt-3 px-auto" 
                  style={darkTheme ? {backgroundImage: 'linear-gradient(black, transparent)'} : {backgroundImage: 'linear-gradient(white, transparent)'}}>
                    <img className="ps-3" src={ darkTheme ? logoWLN : logoWLNLight} style={{maxHeight: '65px'}} alt="landingLogo"/>
                    <h5 className={`${darkTheme && 'text-white'} my-4`}>
                        {t("landing-label-1")}
                    </h5>
                    <h5 className={`${darkTheme && 'text-white'} mb-4`}>
                        {t("landing-label-2")}
                    </h5>
                    <div className="d-flex align-items-center justify-content-center ms-5">                                
                        <h2 className={`${darkTheme && 'text-white'} mt-0 me-5`}><i><b>{t('BY')}</b></i></h2>
                        <img className="ps-5 col-5" src={ darkTheme ? logo : logoLight} style={{maxHeight: '160px'}} alt="landingLogo"/>
                    </div>
                    <div className="mt-4">
                        <Link to="/login/code" className="m-2" style={{minWidth: '200px'}}>
                            <button className="p-2 rounded border-0 landing-btn text-uppercase">{t("access-code-button")}</button>
                        </Link>
                        <div style={{ minHeight: '20px' }}/>
                        <Link to="/login/email" className="m-2" style={{minWidth: '200px'}}>
                            <button className="p-2 rounded border-0 landing-btn text-uppercase">{t("access-email-button")}</button>
                        </Link>
                    </div>
                </center>
            </div>
        </>
    )
}

export default withNamespaces()(Landing);